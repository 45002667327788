/** @format */

import React, { useState, useEffect } from "react";
import ProviderLayout from "views/providers/Provider";
import { Style } from "utils/select";
import { Button, Col } from "react-bootstrap";
import Select from "react-select";
import dayjs from "dayjs";
import { useGetFellowsAttendanceQuery } from "store/services/providerAPI";
import { FadeLoader } from "react-spinners";
import { useGetCohortsQuery } from "store/services/adminAPI";
import { isAfter, isBefore, isSameDay, addDays } from "date-fns";
import { ReactComponent as TimeIcon } from "images/svg/time.svg";
import AttendaceFellowsTable from "views/providers/components/table/fellows/AttendaceFellowsTable";

const ProviderAttendance = () => {
  const [selectedCohort, setCohorts] = useState(null);
  const [schedule, setSchedule] = useState(null);
  const {
    data: cohorts,
    isLoading: loadingCohorts,
    isFetching: fetchingCohorts,
  } = useGetCohortsQuery("");

  const [skip, setSkip] = useState(false);
  const [filter, setFilter] = useState(`page=1`);
  const [selected, setSelected] = useState(false);

  // const [skip, setSkip] = useState(true);
  // const [filter, setFilter] = useState(
  //   `page=1&attendance_schedule=e7eeeae5-e1e7-4009-9816-90421900305e`
  // );
  // const [selected, setSelected] = useState(true);

  const {
    data,
    isLoading: loadingFellows,
    isFetching: fetchingFellows,
  } = useGetFellowsAttendanceQuery(filter, {
    skip: skip,
  });

  useEffect(() => {
    if (selected || (filter && filter.length > 0)) {
      setSkip(false);
    }
  }, [filter, selected]);

  const CohortOptions =
    cohorts?.data?.cohorts?.length > 0
      ? cohorts?.data?.cohorts?.map((cohort) => ({
          value: cohort?.id,
          label: `Cohort ${cohort?.rank}`,
        }))
      : [];

  return (
    <ProviderLayout>
      <div className="row">
        {loadingCohorts || fetchingCohorts ? (
          <div className="d-flex justify-content-center align-items-center height-15 bg-white ">
            <FadeLoader color="#36d7b7" />
          </div>
        ) : (
          <>
            <div className="card">
              <div className="card-header">
                <div className="pt-0 d-flex w-100  flex-column">
                  <h3 className="modal-title">Select Cohort/Week</h3>

                  {selected ? (
                    <div className="card-schedule mt-3">
                      <span className={`side-label bg-primary`}></span>
                      <div className="up-comming-schedule">
                        <div>
                          <h4>{selectedCohort?.label}</h4>
                          <div className="mb-sm-0 mb-2">
                            <img src={null} className="me-2" alt="" />
                            <span>Week{schedule?.week_number}</span>
                          </div>
                        </div>
                        <div className="d-flex align-items-center">
                          <TimeIcon />
                          <span>
                            {dayjs(schedule?.start_date).format("MMMM D, YYYY")}{" "}
                            to{" "}
                            {dayjs(schedule?.end_date).format("MMMM D, YYYY")}
                          </span>
                        </div>
                        <div className="d-flex align-items-center">
                          {/* <ClockIcon /> */}

                          <span>&nbsp;</span>
                        </div>
                        <div>
                          <Button
                            className="btn btn-primary btn-sm"
                            onClick={() => setSelected(false)}
                          >
                            Edit
                          </Button>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <>
                      <div className="d-flex justify-content-between">
                        {CohortOptions?.length > 0 && (
                          <Col md={5}>
                            <p className="p-1 m-0">Choose Cohort</p>
                            <div className="form-group mb-3">
                              <Select
                                value={selectedCohort}
                                onChange={setCohorts}
                                options={CohortOptions}
                                styles={Style}
                              />
                            </div>
                          </Col>
                        )}

                        {selectedCohort && (
                          <Col md={6}>
                            <p className="p-1 m-0">Weeks</p>
                            <div className="form-group mb-3">
                              {cohorts.data?.cohorts
                                ?.filter(
                                  (_cohort) =>
                                    _cohort?.id === selectedCohort?.value
                                )[0]
                                ?.schedules?.map((_sche) => {
                                  const startDate = new Date(_sche?.start_date);
                                  const endDate = new Date(_sche?.end_date);
                                  const currentDate = new Date();
                                  const modifiedDate = addDays(endDate, 7);

                                  // const isEligible =
                                  //   isAfter(currentDate, startDate) &&
                                  //   (isBefore(currentDate, endDate) ||
                                  //     isSameDay(currentDate, endDate));

                                  const isEligible =
                                    isAfter(currentDate, startDate) &&
                                    (isBefore(currentDate, modifiedDate) ||
                                      isSameDay(currentDate, modifiedDate));

                                  return (
                                    <div
                                      className={`d-flex align-items-center justify-content-between weeks ml-2 mb-2 ${
                                        isEligible ? "" : "disabled"
                                      }`}
                                    >
                                      <p className="mr-2">
                                        {
                                          _sche?.week_number === 1 || _sche?.week_number === 2 ? (
                                            "Orientation Week "
                                          ) : `Week ${_sche?.week_number} `
                                        }
                                         (
                                        {dayjs(_sche?.start_date).format(
                                          "MMMM D, YYYY"
                                        )}
                                        {" - "}
                                        {dayjs(_sche?.end_date).format(
                                          "MMMM D, YYYY"
                                        )}
                                        )
                                      </p>
                                      <div>
                                        <input
                                          type="checkbox"
                                          checked={
                                            schedule?.id === _sche?.id ||
                                            isAfter(currentDate, modifiedDate)
                                              ? true
                                              : false
                                          }
                                          onChange={() => {
                                            schedule?.id === _sche?.id
                                              ? setSchedule(null)
                                              : setSchedule(_sche);
                                          }}
                                        />
                                      </div>
                                    </div>
                                  );
                                })}
                            </div>
                          </Col>
                        )}
                      </div>

                      <div className="d-flex w-100 justify-content-center align-items-center">
                        <Button
                          variant="primary"
                          disabled={
                            (selectedCohort && schedule) ||
                            loadingFellows ||
                            fetchingFellows
                              ? false
                              : true
                          }
                          type="button"
                          onClick={() => {
                            let query = `attendance_schedule=${schedule?.id}`;
                            setFilter(query);
                            setSelected(true);
                          }}
                        >
                          {loadingFellows || fetchingFellows
                            ? "Loading....."
                            : "Select"}
                        </Button>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>

            {selected && (
              <div className="row">
                {loadingFellows || fetchingFellows ? (
                  <div className="d-flex justify-content-center align-items-center height-15 bg-white ">
                    <FadeLoader color="#36d7b7" />
                  </div>
                ) : (
                  <div className="card">
                    <div className="card-header">
                      <div className="pt-0 d-flex w-100  flex-column">
                        <h3 className="modal-title">Fellows List</h3>
                        <AttendaceFellowsTable
                          fellows={data?.data?.results ?? []}
                          metadata={data?.data?.metadata ?? null}
                          setFilter={setFilter}
                          setSkip={setSkip}
                          filtering={fetchingFellows || loadingFellows}
                          filter={filter}
                          cohort={selectedCohort}
                          schedule={schedule}
                        />
                      </div>
                    </div>
                  </div>
                )}
              </div>
            )}
          </>
        )}
      </div>
    </ProviderLayout>
  );
};
export default ProviderAttendance;
