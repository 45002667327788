/** @format */

import React, { useState, useEffect } from "react";
import { Button, Modal, Col } from "react-bootstrap";
import Select from "react-select";
import { Style } from "utils/select";
import { toast } from "react-toastify";
import { useGetCohortsQuery } from "store/services/adminAPI";
import {
  useGetProviderCoursesQuery,
  useGetProviderCourseModuleQuery,
  useGetProviderAssessmentsQuery,
} from "store/services/providerAPI";
import { weeks, AssesmentStatus } from "utils/constant.data";

export const SubmissionFilterModal = ({
  showModal,
  setShowFilter,
  setFilter,
  filtering,
  setExportFilter
}) => {
  const [selectedModule, setModule] = useState(null);
  const [selectedWeek, setWeek] = useState(null);
  const [selectedStatus, setStatus] = useState(null);
  const [selectedCohorts, setCohorts] = useState(null);
  const [selectedCategory, setCategory] = useState(null);
  const [selectedAssesment, setAssesment] = useState(null);
  const [skip, setSkip] = useState(true);

  const { data: courses, isLoading: fetchingCourses } =
    useGetProviderCoursesQuery();

  const { data: cohorts, isLoading: loadingCohorts } = useGetCohortsQuery("");
  const { data: modules, isLoading: loadingModules } =
    useGetProviderCourseModuleQuery(`course_ids=${selectedCategory?.value}`, {
      skip: skip,
    });
  const { data: assesments, isLoading: fetchingAssesments } =
    useGetProviderAssessmentsQuery("page=1");

  const ClearIndicator = (props) => {
    const {
      children = "Clear Status",
      getStyles,
      innerProps: { ref, ...restInnerProps },
    } = props;
    return (
      <div
        {...restInnerProps}
        ref={ref}
        style={getStyles("clearIndicator", props)}
      >
        <div style={{ padding: "0px 5px" }}>{children}</div>
      </div>
    );
  };

  const handleFilter = () => {
    if (
      !selectedWeek &&
      !selectedCohorts &&
      !selectedStatus &&
      !selectedModule &&
      !selectedAssesment
    ) {
      toast.warn("✔️ Please Select a Filter !", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    } else {
      // console.log({
      //   module_id: selectedModule?.value ? selectedModule?.value : "",
      //   week_number: selectedWeek?.value ?? "1",
      //   status: selectedStatus?.value ?? "",
      //   cohort_number: selectedCohorts?.value ? selectedCohorts?.value : "",
      // });
      setFilter({
        module_id: selectedModule?.value ? selectedModule?.value : "",
        week_number: selectedWeek?.value ?? "1",
        status: selectedStatus?.value ?? "",
        cohort_number: selectedCohorts?.value ? selectedCohorts?.value : "",
        assessment_id: selectedAssesment?.value ? selectedAssesment?.value : "",
      });
    }
  };

  const Modules = modules?.data?.results
    ?.filter((_) => {
      return _?.status === "active";
    })
    ?.map((_) => {
      return {
        value: _?.id,
        label: _?.name,
        week_number: _?.week_number,
      };
    });

  const CohortOptions =
    cohorts?.data?.cohorts?.length > 0
      ? cohorts?.data?.cohorts?.map((cohort) => ({
          value: cohort?.rank,
          label: `Cohort ${cohort?.rank}`,
        }))
      : [];

  const Categories =
    courses?.data
      ?.filter((_) => {
        return _?.course?.status === "active";
      })
      ?.map((role) => ({
        value: role?.course?.id,
        label: role?.course?.title,
        code: role?.course?.code,
      })) ?? [];

  const _Assesments =
    assesments?.data?.results
      ?.filter((_) => {
        return _?.status?.toLowerCase() === "active";
      })
      ?.map((_) => ({
        value: _?.id,
        label: _?.title,
        cohort: _?.cohort,
      })) ?? [];


  useEffect(() => {
    if (selectedCategory?.value) {
      setSkip(false);
    } else {
      setSkip(true);
    }
  }, [selectedCategory?.value]);

  return (
    <>
      <Modal
        show={showModal}
        onHide={() => setShowFilter(false)}
        className="modal fade"
        id="postModal"
      >
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Filter Fellows</h5>
            <Button
              variant=""
              type="button"
              className="close"
              data-dismiss="modal"
              onClick={() => setShowFilter(false)}
            >
              <span>×</span>
            </Button>
          </div>
          <div className="modal-body">
            <div className="basic-form">
              <form onSubmit={(e) => e.preventDefault()}>
                <div className="col-lg-12">
                  <div className="form-group mb-3">
                    <label>Week Number</label>
                    <Select
                      components={{ ClearIndicator }}
                      value={selectedWeek}
                      onChange={setWeek}
                      options={weeks}
                      styles={Style}
                    />
                  </div>
                </div>

                <div className="col-lg-12">
                  <div className="form-group mb-3">
                    <label>Status</label>
                    <Select
                      components={{ ClearIndicator }}
                      value={selectedStatus}
                      onChange={setStatus}
                      options={AssesmentStatus}
                      styles={Style}
                    />
                  </div>
                </div>

                {loadingCohorts ? (
                  <p>Loading Cohorts.....</p>
                ) : (
                  CohortOptions &&
                  CohortOptions?.length > 0 && (
                    <Col md={12}>
                      <p className="p-1 m-0">Filter by Cohort</p>
                      <div className="form-group mb-3">
                        <Select
                          value={selectedCohorts}
                          onChange={setCohorts}
                          options={CohortOptions}
                          styles={Style}
                        />
                      </div>
                    </Col>
                  )
                )}

                {fetchingCourses ? (
                  <p>Loading Courses.....</p>
                ) : (
                  Categories && (
                    <Col md={12}>
                      <p className="p-1 m-0">Filter by Course</p>
                      <div className="form-group mb-3">
                        <Select
                          value={selectedCategory}
                          onChange={setCategory}
                          options={Categories}
                          styles={Style}
                          components={{ ClearIndicator }}
                        />
                      </div>
                    </Col>
                  )
                )}

                {loadingModules ? (
                  <p>Loading Modules.....</p>
                ) : (
                  Modules && (
                    <div className="col-lg-12">
                      <div className="form-group mb-3">
                        <label>Modules</label>
                        <Select
                          value={selectedModule}
                          onChange={(_) => {
                            setModule(_);
                          }}
                          options={Modules}
                          styles={Style}
                        />
                      </div>
                    </div>
                  )
                )}

                {fetchingAssesments ? (
                  <p>Loading Assesments.....</p>
                ) : (
                  _Assesments && (
                    <div className="col-lg-12">
                      <div className="form-group mb-3">
                        <label>Assesments</label>
                        <Select
                          value={selectedAssesment}
                          onChange={(_) => {
                            setAssesment(_);
                          }}
                          options={_Assesments}
                          styles={Style}
                        />
                      </div>
                    </div>
                  )
                )}
              </form>
            </div>

            <div className="d-flex w-100 justify-content-center align-items-center">
              <Button
                className="ms-2 me-2 py-3 btn-pigment-green-outline "
                style={{ width: "100% !important" }}
                type="button"
                onClick={() => {
                  setModule(null);
                  setWeek(null);
                  setStatus(null);
                  setCohorts(null);
                  setCategory(null);
                  setAssesment(null);
                  setExportFilter("");
                }}
              >
                Clear Filter
              </Button>

              <Button
                onClick={() => {
                  handleFilter();
                }}
                type="button"
                className="ms-2 pull-right  py-3 btn-pigment-green"
              >
                {filtering ? "Filtering ...." : "Filter Resource"}
              </Button>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default SubmissionFilterModal;
