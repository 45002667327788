/**
 * eslint-disable no-unused-vars
 *
 * @format
 */

/** @format */

import React, { useMemo, useEffect, useState, useCallback } from "react";
import {
  useTable,
  useGlobalFilter,
  useFilters,
  useSortBy,
  usePagination,
} from "react-table";
import "./helpers/filtering.css";
import { ReactComponent as BagIcon } from "images/svg/bag.svg";

import { Dropdown, Modal, Button } from "react-bootstrap";
import { capitalize, truncateString } from "utils/strings";
// import { formatDate } from "utils/date";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import {
  replaceUnderscoresWithSpaces,
  extractErrorMessage,
} from "utils/strings";
import { ColumnFilter } from "./helpers/Columns/ColumnFilter";
import {
  useApproveFellowFellowAssessmentMutation,
  useBulkApproveAssessmentsStatusMutation,
  useExportAssessmentsMutation
} from "store/services/providerAPI";
import { useFormik } from "formik";
import * as Yup from "yup";
import { FadeLoader } from "react-spinners";
import SubmissionFilterModal from "./components/SubmissionFilterModal";
import {UploadModal} from "./components/UploadModal";
import { useFlag } from "context/FeatureFlags";



const ProviderSubmissionTable = ({
  resources,
  metadata,
  setFilter,
  setSkip,
  messageClass,
  filtering,
}) => {
  const [modalFilter, setModalFilter] = useState(null);
  const [showFilter, setShowFilter] = useState(false);
  const [filtered, setFiltered] = useState(false);
  const [showUploadModal, setShowUploadModal] = useState(false)
  const [exportFilter, setExportFilter] = useState("")
  const { features } = useFlag();

  useEffect(() => {
    setSkip(true);
  }, [resources, setSkip]);

  const [selectedIds, setSelectedIds] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [action, setAction] = useState("");

  const handleRowSelection = useCallback(
    (id) => {
      if (selectedIds.includes(id)) {
        setSelectedIds(selectedIds.filter((selectedId) => selectedId !== id));
      } else {
        setSelectedIds([...selectedIds, id]);
      }
    },
    [selectedIds]
  );

  const handleSelectAll = () => {
    if (!selectAll) {
      const allIds = resources.map((_F) => _F?.id);
      setSelectedIds(allIds);
    } else {
      setSelectedIds([]);
    }
    setSelectAll(!selectAll);
  };

  const BROADCAST_COLUMNS = (selectedIds, handleRowSelection) => [
    {
      Header: "",
      accessor: "id",
      disableSortBy: true,
      Cell: ({ row }) => {
        return (
          <input
            type="checkbox"
            checked={selectedIds.includes(row?.original?.id) ? true : false}
            onChange={() => handleRowSelection(row?.original?.id)}
          />
        );
      },
    },
    {
      Header: "Assement Name",
      accessor: "title",
      Filter: ColumnFilter,
      disableFilters: true,
      sortable: false,
      Cell: ({ cell }) => {
        return `${capitalize(cell?.row?.original?.assessment?.title)}`;
      },
    },

    {
      Header: "Fellow Name",
      accessor: "fellow",
      Filter: ColumnFilter,
      disableFilters: true,
      sortable: false,
      Cell: ({ cell }) => {
        return truncateString(
          `${capitalize(cell?.row?.original?.fellow?.first_name)} ${capitalize(
            cell?.row?.original?.fellow?.last_name
          )}`
        );
      },
    },

    {
      Header: "Fellow ID",
      accessor: "assessment_id",
      Filter: ColumnFilter,
      disableFilters: true,
      sortable: false,
      Cell: ({ cell }) => {
        return `${capitalize(cell?.row?.original?.fellow?.fellow_id)}`;
      },
    },

    {
      Header: "Week Number",
      accessor: "assessment",
      Filter: ColumnFilter,
      disableFilters: true,
      sortable: false,
      Cell: ({ cell }) => {
        return `${cell?.row?.original?.assessment?.week_number}`;
      },
    },

    {
      Header: "Course",
      accessor: "start_date",
      Filter: ColumnFilter,
      disableFilters: true,
      sortable: false,
      Cell: ({ cell }) => {
        return `${capitalize(cell?.row?.original.assessment?.course?.title)}`;
      },
    },
    {
      Header: "Actions",
      disableSortBy: true,
      sortable: false,
      accessor: "",
      Filter: ColumnFilter,
      disableFilters: true,
      Cell: ({ cell }) => {
        const [showProfile, setShowProfile] = useState(false);

        const { fellow, assessment, link, status } = cell?.row?.original;
        const value = status;

        const [
          approveFellowFellowAssessment,
          { isLoading: updatingAssesment },
        ] = useApproveFellowFellowAssessmentMutation();

        const handleSubmit = async (values) => {
          await approveFellowFellowAssessment({
            assessment_id: assessment?.id,
            fellow_id: fellow?.id,
            status: values.status,
            ...(values.status.toLowerCase() !== "approved" && {
              reason: formik.values?.reason ?? "Null",
            }),
          })
            .unwrap()
            .then((data) => {
              toast.success(`✔️ Application submitted succesfully!`, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
              });
              window.location.reload();
            })
            .catch((error) => {
              const formattedError = extractErrorMessage(error);
              Swal.fire({
                title: replaceUnderscoresWithSpaces(error?.data.detail),
                iconHtml: ` <i class="fa fa-exclamation-triangle text-danger" aria-hidden="true"></i>`,
                text: formattedError,
                showCloseButton: true,
                customClass: {
                  icon: "no-border",
                },
                confirmButtonText: "Try Again",
              });
            });
        };

        const formik = useFormik({
          initialValues: {
            status: "",
            reason: "",
          },
          validationSchema: FormSchema,
          onSubmit: handleSubmit,
          enableReinitialize: true,
        });

        const handleStatus = async (status) => {
          await approveFellowFellowAssessment({
            assessment_id: assessment?.id,
            fellow_id: fellow?.id,
            status: status,
            ...(status.toLowerCase() !== "approved" && {
              reason: formik.values?.reason ?? "Null",
            }),
          })
            .unwrap()
            .then((data) => {
              toast.success(`✔️ Application submitted succesfully!`, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
              });
              window.location.reload();
            })
            .catch((error) => {
              const formattedError = extractErrorMessage(error);
              Swal.fire({
                title: replaceUnderscoresWithSpaces(error?.data.detail),
                iconHtml: ` <i class="fa fa-exclamation-triangle text-danger" aria-hidden="true"></i>`,
                text: formattedError,
                showCloseButton: true,
                customClass: {
                  icon: "no-border",
                },
                confirmButtonText: "Try Again",
              });
            });
        };

        return (
          <>
            <Modal
              show={showProfile}
              onHide={() => setShowProfile(false)}
              className="modal fade"
              id="statusModal"
            >
              <div className="modal-content">
                <div className="modal-header">
                  <h3 className="modal-title">Approve Fellow's Submission</h3>
                  <Button
                    variant=""
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    onClick={() => setShowProfile(false)}
                  >
                    <span>×</span>
                  </Button>
                </div>
                <div className="modal-body">
                  <div className="col-xl-12">
                    <div className="course-dedails-bx">
                      <div className="border-0 pb-0">
                        <h5>{capitalize(assessment?.title)}</h5>
                      </div>
                      <div className="pt-0">
                        <div className="description">
                          <h6>{capitalize(fellow?.first_name)}</h6>
                        </div>

                        <div className="description">
                          <p>{capitalize(fellow?.fellow_id)}</p>
                        </div>

                        <div className="description">
                          <p>{capitalize(assessment?.description)}</p>
                        </div>

                        <div className="description">
                          <a
                            target="_blank"
                            rel="noopener noreferrer"
                            className="text-primary text-bold"
                            href={assessment?.link}
                          >
                            View Assignment
                          </a>
                        </div>

                        <form className="mt-4" onSubmit={formik.handleSubmit}>
                          <div className="form-group mb-3">
                            <label className="mb-1">
                              <strong>Update Status</strong>
                            </label>
                            <select
                              id="status"
                              name="status"
                              className="form-control"
                              value={formik.values.status}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                            >
                              <option>Choose Status</option>
                              {["approved", "disapproved"].map((state) => {
                                return (
                                  <option value={state}>
                                    {capitalize(state)}
                                  </option>
                                );
                              })}
                            </select>
                            {formik.errors.status && formik.touched.status ? (
                              <div className="text-danger mt-2 fs-12">
                                {formik.errors.status}
                              </div>
                            ) : null}
                          </div>
                          {formik.values?.status === "disapproved" && (
                            <div className="mb-3">
                              <label className="mb-1">
                                <strong>Reason</strong>
                              </label>

                              <textarea
                                className="w-100 form-control"
                                placeholder="Your reason"
                                id="reason"
                                name="reason"
                                rows="3"
                                {...formik.getFieldProps("reason")}
                              />

                              <div className="d-flex align-items-center justify-content-between">
                                {formik.errors.reason &&
                                formik.touched.reason ? (
                                  <div className="text-danger mt-2 fs-12">
                                    {formik.errors.reason}
                                  </div>
                                ) : null}
                              </div>
                            </div>
                          )}

                          <div className="d-flex w-100 justify-content-center align-items-center">
                            <Button
                              type="submit"
                              className="ms-2 pull-right  py-3 btn-pigment-green"
                            >
                              {updatingAssesment ? (
                                <span
                                  role="status"
                                  aria-hidden="true"
                                  className="spinner-border spinner-border-sm ms-2 text-white"
                                ></span>
                              ) : (
                                "Submit"
                              )}
                            </Button>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Modal>
            {/* Profile Modal */}

            <div className="d-flex align-items-center">
              <div className="d-flex align-items-center">
                {value?.toLowerCase() === "submitted" && (
                  <>
                    <button
                      className="btn btn-primary mr-2"
                      onClick={() => handleStatus("approved")}
                    >
                      {updatingAssesment ? (
                        <span
                          role="status"
                          aria-hidden="true"
                          className="spinner-border spinner-border-sm ms-2 text-white"
                        ></span>
                      ) : (
                        "Approve"
                      )}
                    </button>
                    <button
                      className="btn btn-danger"
                      onClick={() => {
                        formik.setFieldValue("status", "disapproved");
                        setShowProfile(true);
                      }}
                    >
                      {updatingAssesment ? (
                        <span
                          role="status"
                          aria-hidden="true"
                          className="spinner-border spinner-border-sm ms-2 text-white"
                        ></span>
                      ) : (
                        "Disapprove"
                      )}
                    </button>
                  </>
                )}

                {value?.toLowerCase() === "disapproved" && (
                  <button className="btn btn-danger btn-disabled disabled">
                    {updatingAssesment ? (
                      <span
                        role="status"
                        aria-hidden="true"
                        className="spinner-border spinner-border-sm ms-2 text-white"
                      ></span>
                    ) : (
                      "Disapproved"
                    )}
                  </button>
                )}

                {value?.toLowerCase() === "approved" && (
                  <button className="btn btn-primary btn-disabled disabled">
                    {updatingAssesment ? (
                      <span
                        role="status"
                        aria-hidden="true"
                        className="spinner-border spinner-border-sm ms-2 text-white"
                      ></span>
                    ) : (
                      "Approved"
                    )}
                  </button>
                )}
              </div>

              <Dropdown className="dropdown ms-auto text-right">
                <Dropdown.Toggle
                  variant=""
                  className="btn-link i-false"
                  data-toggle="dropdown"
                >
                  <svg
                    width="24px"
                    height="24px"
                    viewBox="0 0 24 24"
                    version="1.1"
                  >
                    <g
                      stroke="none"
                      strokeWidth={1}
                      fill="none"
                      fillRule="evenodd"
                    >
                      <rect x={0} y={0} width={24} height={24} />
                      <circle fill="#000000" cx={5} cy={12} r={2} />
                      <circle fill="#000000" cx={12} cy={12} r={2} />
                      <circle fill="#000000" cx={19} cy={12} r={2} />
                    </g>
                  </svg>
                </Dropdown.Toggle>
                <Dropdown.Menu className="dropdown-menu dropdown-menu-right">
                  <Dropdown.Item onClick={() => setShowProfile(true)}>
                    Update Status
                  </Dropdown.Item>
                  <Dropdown.Item onClick={() => window.open(link, "_blank")}>
                    Go to Assignment
                  </Dropdown.Item>
                  {/* <Dropdown.Item onClick={() => window.open(link, "_blank")}>
                    Go to Assignment
                  </Dropdown.Item> */}
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </>
        );
      },
    },
  ];

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const columns = useMemo(
    () => BROADCAST_COLUMNS(selectedIds, handleRowSelection),
    [selectedIds, handleRowSelection]
  );
  const data = useMemo(() => resources, [resources]);

  const tableInstance = useTable(
    { columns, data, initialState: { pageIndex: 0 } },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    tableInstance;

  const hasNext = metadata?.next > 0;
  const hasPrev = metadata?.previous > 0;

  const handleAllFilter = (_modalFilter, _page) => {
    const filterString = `page=${_page}${
      String(modalFilter?.week_number)?.length > 0
        ? `&week_number=${modalFilter?.week_number}`
        : ""
    }${
      modalFilter?.status?.length > 0 ? `&status=${modalFilter?.status}` : ""
    }${
      String(modalFilter?.cohort_number ?? "")?.length > 0
        ? `&cohort_number=${modalFilter?.cohort_number}`
        : ""
    }${
      modalFilter?.module_id?.length > 0
        ? `&module_id=${modalFilter?.module_id}`
        : ""
    }${
      modalFilter?.assessment_id?.length > 0
        ? `&assessment_id=${modalFilter?.assessment_id}`
        : ""
    }`;

    setFiltered(true);
    setFilter(filterString);
    setShowFilter(false);
    setExportFilter(filterString)
  };

  useEffect(() => {
    if (modalFilter) {
      handleAllFilter(modalFilter, 1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modalFilter]);

  const [bulkApproveAssessmentsStatus, { isLoading: _updatingAssesment }] =
    useBulkApproveAssessmentsStatusMutation();

  const [exportAssessments, {isLoading:exportingAssessments}] = useExportAssessmentsMutation()

  const handleAssessmentExport = async () => {
    const filteredExportFilter = exportFilter.replace(/^page=1&/, '')
    await exportAssessments(filteredExportFilter)

    .unwrap()
    .then((data)=>{
      Swal.fire({
        title: "Success",
        text: data?.detail,
        icon: "success",
        showCloseButton: true,
        customClass: {
          icon: "no-border",
        },
        confirmButtonText: "ok",
      });
    })
    .catch((error) => {
      const formattedError = extractErrorMessage(error);
      Swal.fire({
        title:
          replaceUnderscoresWithSpaces(error?.data?.code) || "Error Occured",
        text: formattedError,
        iconHtml: ` <i class="fa fa-exclamation-triangle text-danger" aria-hidden="true"></i>`,
        showCloseButton: true,
        customClass: {
          icon: "no-border",
        },
        confirmButtonText: "Try Again",
      });
    });
  }

  const handleBulkUpdate = async () => {
    await bulkApproveAssessmentsStatus({
      status: action,
      fellow_assessments: [...selectedIds],
    })
      .unwrap()
      .then((data) => {
        Swal.fire({
          title: "Success",
          text: `Fellow status updated`,
          icon: "success",
          showCloseButton: true,
          customClass: {
            icon: "no-border",
          },
          confirmButtonText: "ok",
        });
        setSelectedIds([]);
        setAction("");
      })
      .catch((error) => {
        const formattedError = extractErrorMessage(error);
        Swal.fire({
          title:
            replaceUnderscoresWithSpaces(error?.data?.code) || "Error Occured",
          text: formattedError,
          iconHtml: ` <i class="fa fa-exclamation-triangle text-danger" aria-hidden="true"></i>`,
          showCloseButton: true,
          customClass: {
            icon: "no-border",
          },
          confirmButtonText: "Try Again",
        });
      });
  };

  return (
    <>
      <SubmissionFilterModal
        showModal={showFilter}
        setShowFilter={setShowFilter}
        setFilter={setModalFilter}
        filtering={filtering}
        setExportFilter={setExportFilter}
      />
      <UploadModal 
            showUploadModal={showUploadModal}
            setShowUploadModal={setShowUploadModal}
          />

      <div className="card">
        <div className={`card-header ${filtering ? "disabled" : ""}`}>
          <div className="d-flex  align-items-center">
            <div className="d-flex  align-items-center">
              <div className="mr-2">
                <input
                  type="checkbox"
                  checked={selectAll}
                  onChange={() => handleSelectAll()}
                />
              </div>
              <h4 className="card-title text-pigment-green">All Assesments</h4>
              {selectedIds?.length > 0 && (
                <div className="ml-2">
                  <select
                    className="form-control bulk-select"
                    id="action"
                    value={action}
                    onChange={(e) => setAction(e.target.value)}
                  >
                    <option value="">Choose Bulk Option</option>
                    <option value="approved">Approve</option>
                    <option value="disapproved">Disapprove</option>
                  </select>
                </div>
              )}

              {selectedIds?.length > 0 && action?.length > 0 && (
                <button
                  className="btn btn-primary ml-2"
                  onClick={() => handleBulkUpdate()}
                >
                  {_updatingAssesment ? (
                    <span
                      role="status"
                      aria-hidden="true"
                      className="spinner-border spinner-border-sm ms-2 text-white"
                    ></span>
                  ) : (
                    "Submit"
                  )}
                </button>
              )}
            </div>

            {filtered && (
              <button
                onClick={() => {
                  setFiltered(false);
                  setFilter(`page=1`);
                  setModalFilter(null);
                  setExportFilter("")
                }}
                className="btn text-danger"
              >
                Reset Filter
              </button>
            )}
          </div>

          <div className="d-flex  align-items-center">
            <button
              type="button"
              className="btn btn-primary ml ms-2"
              onClick={() => {
                setSkip(true);
                setShowFilter(true);
              }}
            >
              Filter
            </button>

            {features?.prodMatchingEnabled && (
              <button
                type="button"
                className="btn btn-primary ml ms-2"
                onClick={() => {
                  handleAssessmentExport()
                }}
              >
                {exportingAssessments ? (
                        <span
                          role="status"
                          aria-hidden="true"
                          className="spinner-border spinner-border-sm ms-2"
                        ></span>
                      ) : (
                        'Export'
                      )}
              </button>
            )}
            {features?.prodMatchingEnabled && (
            <button
              type="button"
              className="btn btn-primary ml ms-2"
              onClick={() => {
                setSkip(true);
                setShowUploadModal(true);
                console.log("upload clicked")

              }}
            >
              Upload
            </button>
            )}
          </div>
        </div>
        {filtering ? (
          <div className="d-flex justify-content-center align-items-center height-15 bg-white ">
            <FadeLoader color="#36d7b7" />
          </div>
        ) : (
          <div className="card-body">
            {data?.length > 0 ? (
              <div className="table-responsive">
                <div className="dataTables_wrapper">
                  <table
                    {...getTableProps()}
                    className="table dataTable display"
                  >
                    <thead>
                      {headerGroups.map((headerGroup) => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                          {headerGroup.headers.map((column) => (
                            <th
                              {...column.getHeaderProps(
                                column.getSortByToggleProps()
                              )}
                            >
                              {column.render("Header")}
                            </th>
                          ))}
                        </tr>
                      ))}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                      {rows.map((row) => {
                        prepareRow(row);
                        return (
                          <tr {...row.getRowProps()}>
                            {row.cells.map((cell) => {
                              return (
                                <td {...cell.getCellProps()}>
                                  {" "}
                                  {cell.render("Cell")}{" "}
                                </td>
                              );
                            })}
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>

                  <div className="d-flex text-center justify-content-between align-items-center mt-3 mb-md-0 mb-2">
                    <button
                      className={`btn btn-outline ${
                        hasPrev ? "" : "disabled"
                      } `}
                      onClick={() => {
                        const _newPage = Number(metadata?.page - 1) || 1;
                        setFilter(
                          `page=${_newPage}&message_class=${messageClass}`
                        );
                      }}
                    >
                      Previous
                    </button>

                    {metadata?.count && (
                      <div className="d-flex">
                        <p className="mb-0">
                          Page <strong>{metadata?.page}</strong> of{" "}
                          <strong>{Math.ceil(metadata?.count / 100)}</strong>{" "}
                          &nbsp;
                        </p>
                        <p className="mb-0">
                          Total <strong>{metadata?.count}</strong>
                        </p>
                      </div>
                    )}

                    <button
                      className={`btn btn-outline ${
                        hasNext ? "" : "disabled"
                      } `}
                      onClick={() => {
                        const _newPage = Number(metadata?.page || 0) + 1;
                        setFilter(
                          `page=${_newPage}&message_class=${messageClass}`
                        );
                      }}
                    >
                      Next
                    </button>
                  </div>
                </div>
              </div>
            ) : (
              <div className="card">
                <div className="card-body text-center ai-icon  text-primary">
                  <BagIcon />
                  <h4 className="my-2">No data for the specified filter</h4>
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default ProviderSubmissionTable;

const FormSchema = Yup.object().shape({
  status: Yup.string().required("Status is required"),
  reason: Yup.string().test(
    "conditional-reason",
    "Reason is required when status is declined",
    function (value) {
      return (
        this.parent.status !== "disapproved" ||
        (this.parent.status === "disapproved" && !!value)
      );
    }
  ),
});
